import { toast } from "react-toastify"
import axios from "axios"
import { navigate } from "gatsby-link"

// Get all quotations from db
export const getQuotations = data => {
  return async (dispatch, getState) => {
    const skip = data?.skip || 1
    const limit = data?.limit || 25
    const search = data?.search || "" // Add search term

    dispatch({
      type: `SET_QUOTATION_LOADING`,
      data: true,
    })

    if (
      getState().quotation.quotation &&
      getState().quotation.quotations.length > 0
    ) {
      dispatch({
        type: `SET_QUOTATION_LOADING`,
        data: false,
      })
      return
    }

    const token = getState().auth.auth.token

    try {
      let searchParam = search

      // Check if search term is not numeric
      if (search !== "" && !/^\d+$/.test(search)) {
        // Assume it's a customer name and try to find the customer codes
        const customers = getState().customer.customers // Access customers from Redux state

        // Find matching customers based on a case-insensitive substring match
        const matchingCustomers = customers.filter(customer =>
          customer.name.toLowerCase().includes(search.toLowerCase())
        )

        // Take the top result as the search parameter
        const topCustomer = matchingCustomers[0]

        // Use the customer code of the top result as the search term
        if (topCustomer) {
          searchParam = topCustomer.code
        }
      }

      const res = await axios.get(
        `${process.env.GATSBY_API_CALL}/quotations?page=${skip}&limit=${limit}${
          searchParam && "&search=" + searchParam
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      dispatch({
        type: `SET_QUOTATIONS`,
        data: res.data.quotations,
      })

      dispatch({
        type: `SET_TOTAL_QUOTATIONS`,
        data: res.data.totalQuotations,
      })

      dispatch({
        type: `SET_TOTAL_PAGES`,
        data: res.data.totalPages,
      })

      dispatch({
        type: `SET_QUOTATION_LOADING`,
        data: false,
      })
    } catch (e) {
      console.log(e)
      toast.error(`Error: ${e.response?.data.error}`)
      dispatch({
        type: `SET_QUOTATION_LOADING`,
        data: false,
      })
    }
  }
}

// Get all quotations from db without pagination (for payment receipt invoice modal)
export const getAllQuotations = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: "SET_QUOTATION_LOADING",
      data: true,
    })

    const token = getState().auth.auth.token

    try {
      const res = await axios.get(
        `${process.env.GATSBY_API_CALL}/all-quotations`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      dispatch({
        type: "SET_QUOTATIONS",
        data: res.data.quotations,
      })
    } catch (error) {
      console.error("Error fetching quotations:", error)
      dispatch({
        type: "SET_QUOTATION_LOADING",
        data: error,
      })
    } finally {
      dispatch({
        type: "SET_QUOTATION_LOADING",
        data: false,
      })
    }
  }
}

// Save new quotation to db
export const addQuotation = () => {
  return async (dispatch, getState) => {
    const token = getState().auth.auth.token

    let quotationData = {
      ...getState().quotation.newQuotation,
      customerCode: getState().quotation.newQuotation.customer.code,
      status: "Pending",
    }

    // Remove the serial numbers from the products
    quotationData.products = quotationData.quotationProducts.map(product => {
      delete product.serialNumbers
      delete product.serialNumbersSold
      delete product.serialNumbersInTransit
      return product
    })

    try {
      const res = await axios.post(
        `${process.env.GATSBY_API_CALL}/quotations`,
        quotationData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      const newQuotations = getState().quotation.quotations.concat(res.data)
      dispatch({
        type: `SET_QUOTATIONS`,
        data: newQuotations,
      })
      toast.success("Success: Quotation added!")
      return res.data.quotationNumber
    } catch (e) {
      console.log(e.response)
      throw new Error(e.response?.data.message)
    }
  }
}

// Cancel quotation
export const cancelQuotation = (id, reason) => {
  return async (dispatch, getState) => {
    const token = getState().auth.auth.token

    try {
      await axios.patch(
        `${process.env.GATSBY_API_CALL}/quotations/${id}`,
        { cancelled: true, reasonForCancel: reason },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      const newQuotations = getState().quotation.quotations.map(quotation => {
        if (quotation._id === id) {
          quotation.cancelled = true
        }
        return quotation
      })

      dispatch({
        type: `SET_QUOTATIONS`,
        data: newQuotations,
      })

      toast.success("Success: Quotation cancelled!")
    } catch (e) {
      console.log(e)
      toast.error(`Error: ${e.response?.data.message} `)
    }
  }
}

//delete quoatation
export const deleteQuotation = id => {
  return async (dispatch, getState) => {
    const token = getState().auth.auth.token

    try {
      await axios.patch(
        `${process.env.GATSBY_API_CALL}/quotations/delete/${id}`,
        { deleted: true },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      const newQuotations = getState().quotation.quotations.map(quotation => {
        if (quotation._id === id) {
          quotation.deleted = true
        }
        return quotation
      })

      dispatch({
        type: `SET_QUOTATIONS`,
        data: newQuotations,
      })

      toast.success("Success: Quotation cancelled!")
    } catch (e) {
      console.log(e)
      toast.error(`Error: ${e.response?.data.message} `)
    }
  }
}

// payment Received
export const paymentReceived = id => {
  return async (dispatch, getState) => {
    const token = getState().auth.auth.token

    try {
      await axios.patch(
        `${process.env.GATSBY_API_CALL}/quotations/paymentReceived/${id}`,
        { paymentReceived: true },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      const newQuotations = getState().quotation.quotations.map(quotation => {
        if (quotation._id === id) {
          quotation.paymentReceived = true
        }
        return quotation
      })

      dispatch({
        type: `SET_QUOTATIONS`,
        data: newQuotations,
      })

      toast.success("Success: Payment Received!")
      navigate("/app/quotations")
    } catch (e) {
      console.log(e)
      toast.error(`Error: ${e.response?.data.message} `)
    }
  }
}

// Credit Confirmation
export const creditConfirmation = id => {
  return async (dispatch, getState) => {
    const token = getState().auth.auth.token

    try {
      await axios.patch(
        `${process.env.GATSBY_API_CALL}/quotations/creditConfirmation/${id}`,
        { creditConfirmation: true },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      const newQuotations = getState().quotation.quotations.map(quotation => {
        if (quotation._id === id) {
          quotation.creditConfirmation = true
        }
        return quotation
      })

      dispatch({
        type: `SET_QUOTATIONS`,
        data: newQuotations,
      })

      toast.success("Success: Credit Confirmed!")
      navigate("/app/quotations")
    } catch (e) {
      console.log(e)
      toast.error(`Error: ${e.response?.data.message} `)
    }
  }
}

// Set quotation products
export const setQuoteProducts = products => {
  return dispatch => {
    dispatch({
      type: `SET_QUOTATION_PRODUCTS`,
      data: products,
    })
  }
}

// Add product to quotation
export const addQuoteProduct = productId => {
  return (dispatch, getState) => {
    const quotationProductsFromState = getState().quotation.newQuotation
      .quotationProducts
    const itemExists = quotationProductsFromState.some(
      prod => prod.id === productId
    )

    if (itemExists) {
      toast.error(`Error. Item has already been added.`)
      return
    }

    const productData = getState().product.products.find(
      prod => prod.id === productId
    )

    const newProducts = [
      ...quotationProductsFromState,
      {
        ...productData,
        quantity: 1,
        discount: 0,
        discountType: "%",
        newWarranty: "10 years",
      },
    ]

    dispatch({
      type: `SET_QUOTATION_PRODUCTS`,
      data: newProducts,
    })
  }
}

// Delete product from quotation
export const deleteQuoteProduct = productId => {
  return (dispatch, getState) => {
    const newProducts = getState().quotation.newQuotation.quotationProducts.filter(
      prod => prod.id !== productId
    )

    dispatch({
      type: `SET_QUOTATION_PRODUCTS`,
      data: newProducts,
    })
  }
}

// Update product quantity in quotation
export const setQuoteProductQuantity = (productId, quantity) => {
  return (dispatch, getState) => {
    const newProducts = getState().quotation.newQuotation.quotationProducts.map(
      prod => {
        if (prod.id === productId) {
          return { ...prod, quantity }
        } else {
          return prod
        }
      }
    )

    dispatch({
      type: `SET_QUOTATION_PRODUCTS`,
      data: newProducts,
    })
  }
}

// Update product discount type in quotation
export const setQuoteProductDiscountType = (productId, discountType) => {
  return (dispatch, getState) => {
    const newProducts = getState().quotation.newQuotation.quotationProducts.map(
      prod => {
        if (prod.id === productId) {
          return { ...prod, discountType }
        } else {
          return prod
        }
      }
    )

    dispatch({
      type: `SET_QUOTATION_PRODUCTS`,
      data: newProducts,
    })
  }
}

// Update product warranty Time in quotation
export const setQuoteWarrantyTime = (productId, newWarranty) => {
  return (dispatch, getState) => {
    const newProducts = getState().quotation.newQuotation.quotationProducts.map(
      prod => {
        if (prod.id === productId) {
          return { ...prod, newWarranty }
        } else {
          return prod
        }
      }
    )

    dispatch({
      type: `SET_QUOTATION_PRODUCTS`,
      data: newProducts,
    })
  }
}

// Update product discount in quotation
export const setQuoteProductDiscount = (productId, discount) => {
  return (dispatch, getState) => {
    const newProducts = getState().quotation.newQuotation.quotationProducts.map(
      prod => {
        if (prod.id === productId) {
          if (discount) {
            return { ...prod, discount }
          } else {
            return { ...prod, discount: 0 }
          }
        } else {
          return prod
        }
      }
    )

    dispatch({
      type: `SET_QUOTATION_PRODUCTS`,
      data: newProducts,
    })
  }
}

// Set Date
export const setQuoteDate = date => {
  return dispatch => {
    dispatch({
      type: `SET_QUOTE_DATE`,
      data: date,
    })
  }
}

// Set Quotation Number
export const setQuotationNumber = number => {
  return dispatch => {
    dispatch({
      type: `SET_QUOTATION_NUMBER`,
      data: number,
    })
  }
}

// Set Payment Method
export const setQuotePaymentMethod = method => {
  return dispatch => {
    dispatch({
      type: `SET_QUOTE_PAYMENT_METHOD`,
      data: method,
    })
  }
}

// Set Sales Manager
export const setQuoteSalesManager = manager => {
  return dispatch => {
    dispatch({
      type: `SET_QUOTE_SALES_MANAGER`,
      data: manager,
    })
  }
}

// Set Payment Terms
export const setQuotePaymentTerms = terms => {
  return dispatch => {
    dispatch({
      type: `SET_QUOTE_PAYMENT_TERMS`,
      data: terms,
    })
  }
}

// Set Deliver To
export const setQuoteDeliverTo = deliverTo => {
  return dispatch => {
    dispatch({
      type: `SET_QUOTE_DELIVER_TO`,
      data: deliverTo,
    })
  }
}
// Set Quote ID
export const setQuoteId = id => {
  return dispatch => {
    dispatch({
      type: `SET_QUOTE_ID`,
      data: id,
    })
  }
}

// Set Customer
export const setQuoteCustomer = customer => {
  return dispatch => {
    dispatch({
      type: `SET_QUOTE_CUSTOMER`,
      data: customer,
    })
  }
}

// Set Customer
export const setQuoteTransport = transport => {
  return dispatch => {
    dispatch({
      type: `SET_QUOTE_TRANSPORT`,
      data: transport,
    })
  }
}

// Clear new quotation fields
export const clearNewQuotation = () => {
  return dispatch => {
    dispatch({
      type: `CLEAR_NEW_QUOTATION`,
    })
  }
}
