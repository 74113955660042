import React, { useMemo } from "react"
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  PDFDownloadLink,
} from "@react-pdf/renderer"
import _ from "lodash"
import { Button } from "react-bootstrap"
import logo from "../../images/logo.png"
var generator = require("generate-serial-number")
const WarrantyCardPdf = ({ data }) => {
  const MyDocument = ({ recipient }) => (
    <Document>
      <Page size="A4" style={styles.page}>
        <View>
          <View style={styles.logo}>
            <Image src={logo} />
          </View>
          <View style={styles.header}>
            <Text style={styles.headerpart1}>WARRANTY</Text>
            <Text style={styles.headerpart2}>CERTIFICATE</Text>
          </View>

          <View
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <View style={styles.middleBody}>
              <Text style={styles.data}>
                Warranty Serial No : {generator.generate(10)}
              </Text>
              <Text style={styles.data}>
                Customer's Name : {data?.customer?.name}
              </Text>
              <Text style={styles.data}>
                Customer's Address: {data?.customer?.address}
              </Text>
              <Text style={styles.data}>
                Invoice No : {data?.invoiceNumber}
              </Text>
              <Text style={styles.data}>Invoice Date : {data?.date}</Text>
            </View>

            <View style={{ width: "35%" }}>
              <View style={styles.marginTop}>
                <Text style={styles.footerInfo}>
                  Gunda Power Private Limited
                </Text>
              </View>
              <View>
                <Text style={styles.footerInfo}>
                  184, Buthgamuwa Road Rajagiriya, Sri Lanka
                </Text>
              </View>
              <View>
                <Text style={styles.footerInfo}>
                  Tel: +94 112872395 / +94 777275626
                </Text>
              </View>
              <View>
                <Text style={styles.footerInfo}>info@gundapower.com</Text>
              </View>
              <View>
                <Text style={styles.footerInfo}>www.gundapower.com</Text>
              </View>
            </View>
          </View>

          {data.products
            .filter((pro, index) => {
              return pro?.warranty || pro?.category?.includes("Inverter")
            })
            .map((pro, index) => {
              return (
                <View key={index} style={styles.productData}>
                  <Text style={{ marginBottom: 15 }}>
                    Product Name: {pro.productName}{" "}
                  </Text>
                  <View>
                    {pro.serialNumbersInInvoice.length > 0 && (
                      <Text style={{ marginBottom: 15 }}>Serial Numbers: </Text>
                    )}

                    {pro.serialNumbersInInvoice[0] && (
                      <View
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          marginBottom: 15,
                        }}
                      >
                        {pro?.serialNumbersInInvoice?.map(serial => {
                          return (
                            <View style={`${styles.flexBox}`}>
                              <Text style={{ marginRight: 20 }}>
                                {serial.main}{" "}
                                {pro.category.includes("Inverter") &&
                                  serial.complimentary &&
                                  `( Wifi Stick 2 Years Warranty: ${serial.complimentary} )`}{" "}
                              </Text>
                            </View>
                          )
                        })}
                      </View>
                    )}
                  </View>
                  <View>
                    <Text>
                      {" "}
                      {pro.category.includes("Inverter") && pro.newWarranty ? (
                        <Text>Warranty Period - {pro.newWarranty}</Text>
                      ) : (
                        <Text>
                          {pro.warranty && `Warranty Period - ${pro.warranty}`}
                        </Text>
                      )}
                    </Text>
                  </View>
                </View>
              )
            })}

          {/* warranty terms */}
          <View wrap={false}>
            {/* Warranty */}
            <Text style={styles.h2}>Terms and Conditions: Warranty</Text>
            {/* <Text style={styles.notesText}>
              * Phono Solar Mono Panel - 12 years warranty for manufacturing
              defects.
            </Text>
            <Text style={styles.notesText}>
              * Phono Solar Poly Panel - 10 years warranty for manufacturing
              defects.
            </Text>
            <Text style={styles.notesText}>
              * Grid Tied Inverters - 10/5 years warranty for manufacturing
              defects.
            </Text> */}
            <Text style={styles.notesText}>
              * Warranty covers manufacturing defects only.
            </Text>
            <Text style={styles.notesText}>
              * Invoice / Warranty card must be produced for warranty claims.
            </Text>
            <Text style={styles.notesText}>
              * For warranty claims, contact Gunda Power (Pvt) Ltd on 0777 275
              626 / 0112872395
            </Text>
            <Text style={styles.notesText}>
              * Good sold are not refundable / returned or exchangeable under
              any circumstances.
            </Text>

            <Text style={styles.notesText}>
              * Wear and Tear on wiring, scratches, stains, etc..that do not
              affect the function of goods, wiring &amp; other accessories on
              equipment cannot be claimed under warranty.
            </Text>
            <Text style={styles.notesText}>
              * Natural perils, misuse of equipment, burnt marks, repairs made
              by 3rd party other than Gunda Power (Pvt) Ltd. and any tampering
              or misuse of equipment renders the warranty void.
            </Text>
            <Text style={styles.notesText}>
              * Installers and End Consumers are advised to insure the Solar
              System under "General Insurance Policy", as under no circumstances
              will Gunda Power (Pvt) Ltd be liable for consequential, special,
              direct/indirect, incidental, punitive damages, losses or expenses
              occurred in any such situations.
            </Text>
            <Text style={styles.notesText}>
              * Anyone installing batteries that are not compatible with off
              grid inverters will take the risk of warranty not being honored by
              the manufacturer company. Similarly we Gunda Power will also not
              issue replacements if Manufacturer does not advice us to offer
              this.
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  )
  return useMemo(() => {
    // if data.products doesnt have any inverter or panel, return null
    if (
      data.products.filter(pro => {
        return (
          pro?.category?.includes("Inverter") ||
          pro?.category?.includes("PV Modules") ||
          pro?.warranty
        )
      }).length === 0
    ) {
      return null
    } else
      return (
        <>
          <PDFDownloadLink
            document={<MyDocument />}
            fileName={`${data.customer?.name} (Customer Copy).pdf`}
          >
            {({ blob, url, loading, error }) =>
              loading ? (
                <p style={{ textTransform: "uppercase", textAlign: "center" }}>
                  Loading Download
                </p>
              ) : (
                <p
                  style={{
                    textTransform: "uppercase",
                    fontFamily: "Montserrat",
                    color: "#126b91",
                    fontWeight: "bold",
                  }}
                >
                  <Button
                    style={{
                      width: "100%",
                      height: "48px",
                      fontSize: "1.5rem",
                    }}
                  >
                    Download Warranty Card
                  </Button>
                </p>
              )
            }
          </PDFDownloadLink>
        </>
      )
  }, [])
}

// Stylesheet
const styles = StyleSheet.create({
  page: {
    backgroundColor: "white",
    paddingHorizontal: 30,
    paddingBottom: 10,
    width: "100%",
    alignItems: "center",
  },
  h2: {
    fontWeight: "bold",
    textDecoration: "underline",
    marginBottom: 5,
    fontSize: 8,
    marginTop: 8,
  },
  notesText: {
    fontSize: 8,
  },
  data: {
    marginTop: 4,
  },
  marginTop: {
    marginTop: 8,
  },

  serielNo: {
    flexDirection: "row",
    paddingTop: 4,
    paddingBottom: 4,
  },
  productData: {
    marginTop: 4,
    fontSize: 10,

    border: true,
    padding: 4,
  },
  flexBox: {
    display: "flex",
    flexDirection: "row",
    paddingTop: 3,
    paddingBottom: 3,
  },
  footerInfo: {
    fontSize: 8,
    marginTop: 3,
  },
  header: {
    alignItems: "center",
  },
  logo: {
    width: "25%",
    marginTop: 20,
    marginBottom: 20,
  },

  headerpart1: {
    fontSize: 26,
  },
  headerpart2: {
    fontSize: 18,
    marginTop: 3,
    borderBottom: true,
    marginBottom: 4,
  },

  middleBody: {
    fontSize: 8,
    textAlign: "justify",
    marginBottom: 4,
    marginTop: 4,
    marginRight: 20,
    width: "65%",
  },
  maxWidth: {
    width: "100%",
    textAlign: "center",
    margin: "auto",
  },
})

export default WarrantyCardPdf
