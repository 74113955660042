import React, { useRef } from "react"
import moment from "moment"
import { connect } from "react-redux"
import DocumentPreview from "../documentPreview"
import BackButton from "../backButton"
import PDFGenerator from "../pdfGenerator"
import Button from "react-bootstrap/Button"
import { confirmAlert } from "react-confirm-alert"
import {
  cancelInvoice,
  reasonForCancle,
  reverceReasonForCancle,
} from "../../state/actions/invoices"
import { setCustomer, setProducts } from "../../state/actions/invoice"
import { setProductsFromDatabase } from "../../state/actions/product"
import { navigate } from "gatsby-link"
import { toast } from "react-toastify"
import Loading from "../loading"

import styles from "../../styles/components/invoices/viewInvoice.module.scss"

const ViewInvoice = props => {
  const invoiceItems = props.invoice.invoiceProducts.map(item => {
    return {
      ...props.products.find(product => product.sku === item.sku),
      price: item.price,
      sku: item.sku,
      discount: item.discount,
      discountType: item.discountType,
      quantity: item.quantity,
      serialNumbersInInvoice: item.serialNumbers,
      newWarranty: item?.newWarranty,
    }
  })

  const invoiceData = {
    documentNumber: props.invoice.invoiceNumber,
    senderAddress: props.senderAddress,
    senderCity: props.senderCity,
    senderTel: props.senderTel,
    customer: props.customer.find(
      customer => customer.code === props.invoice.customerCode
    ),
    user: props.invoice.user,
    date: moment(new Date(props.invoice.date)).format("DD-MM-YYYY"),
    paymentMethod: props.invoice.paymentMethod,
    salesManager: props.invoice.salesManager,
    transport: props.invoice.transport,
    paymentTerms: props.invoice.paymentTerms,
    dueDate: moment(new Date(props.invoice.dueDate)).format("DD-MM-YYYY"),
    deliverTo: props.invoice.deliverTo,
    invoiceProducts: invoiceItems,
    status: props.invoice.cancelled,
    quotationNumber: props.invoice.quotationNumber,
  }

  const handleCancelInvoice = onClose => {
    if (!props.admin) {
      toast.error("Admin privileges required to cancel an invoice.")
      return
    }

    props
      .cancelInvoice(props.invoice._id, props.invoice.invoiceProducts)
      .then(success => {
        if (success) {
          props.setProductsFromDatabase()
          navigate("/app/invoices")
        }
      })
  }

  const reasonCancelInvoice = onClose => {
    props
      .reasonForCancle(props.invoice._id, inputRef.current.value)
      .then(success => {
        if (success) {
          props.setProductsFromDatabase()
          navigate("/app/invoices")
        }
      })

    onClose()
  }

  const reverceReasonCancelInvoice = () => {
    props.reverceReasonForCancle(props.invoice._id).then(success => {
      if (success) {
        props.setProductsFromDatabase()
        navigate("/app/invoices")
      }
    })
  }

  const reverseReasonForCancelInvoice = () => {
    confirmAlert({
      title: "Reverse Cancel Invoice Request",
      message: "Are you sure you want to reverse cancel request",
      buttons: [
        {
          label: "Yes",
          onClick: reverceReasonCancelInvoice,
        },
        {
          label: "No",
        },
      ],
    })
  }

  const inputRef = useRef("")

  const reasonForCancelInvoice = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui border bg-white p-5 rounded">
            <h1>Cancel Invoice</h1>
            <p>Are you sure you want to cancel this Invoice?</p>
            <p>Reason for Cancel</p>
            <input ref={inputRef}></input>

            <br></br>
            <br></br>

            <button className="mr-2 border p-2 " onClick={onClose}>
              No
            </button>
            <button
              className="border p-2"
              onClick={() => reasonCancelInvoice(onClose)}
            >
              Yes
            </button>
          </div>
        )
      },
    })
  }

  const confirmCancelInvoice = () => {
    confirmAlert({
      title: "Cancel Invoice",
      message:
        "Are you sure you want to cancel this invoice? This action is irreversible.",
      buttons: [
        {
          label: "Yes",
          onClick: handleCancelInvoice,
        },
        {
          label: "No",
        },
      ],
    })
  }

  const createCreditNoteHandler = () => {
    confirmAlert({
      title: "Create Credit Note",
      message:
        "Are you sure you want to create a credit note from this invoice?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            props.setDate(moment().format("YYYY-MM-DD"))
            props.setInvoiceNumber(props.invoice.invoiceNumber)
            // props.setInvoiceId(props.id)

            props.setCustomer(
              props.customer.find(
                customer => customer.code === props.invoice.customerCode
              )._id
            )
            props.setProducts(invoiceItems)
            localStorage.setItem("invoiceToCreditNote", "yes")
            navigate("/app/credit-notes/new")
          },
        },
        {
          label: "No",
        },
      ],
    })
  }

  return (
    <div className={styles.wrapper}>
      <h1>
        {props.invoice.invoiceNumber}{" "}
        {props.invoice.cancelled && (
          <span className="text-danger">(Cancelled)</span>
        )}
      </h1>
      <BackButton to="/app/invoices" />
      {/* Cancel Invoice Button */}
      {!props.invoice.cancelled && props.admin && props.canCancelInvoice && (
        <div className="action-buttons mt-5">
          <Button
            variant="light"
            className="mr-4"
            onClick={() => confirmCancelInvoice()}
          >
            Cancel Invoice
          </Button>

          {props.invoice.isCanceled && (
            <Button
              variant="light"
              className="mr-4"
              onClick={() => reverseReasonForCancelInvoice()}
            >
              Don't Cancel Invoice
            </Button>
          )}

          {/* Create New Credit Note Button */}
          {/* <Button
            variant="light"
            className="mr-4"
            onClick={() => createCreditNoteHandler()}
          >
            Create New Credit Note
          </Button> */}
        </div>
      )}
      {!props.canCancelInvoice && (
        <div className="action-buttons mt-5">
          {" "}
          <Button
            variant="light"
            className="mr-4"
            onClick={reasonForCancelInvoice}
          >
            Request to Cancel
          </Button>{" "}
        </div>
      )}
      {
        <div className="text-danger mt-1">
          {props.invoice?.isCanceled &&
            props?.invoice?.reasonForCancel &&
            `reason for cancel - ${props?.invoice?.reasonForCancel}`}
        </div>
      }

      <DocumentPreview data={invoiceData} document="invoice" />

      {/* Download Button */}
      {!props.isLoading && !props.invoice.cancelled ? (
        <PDFGenerator document="invoice" data={invoiceData} />
      ) : (
        !props.invoice.cancelled && (
          <Loading height="" text="Loading PDF Download" />
        )
      )}
    </div>
  )
}

const mapStateToProps = (state, props) => {
  return {
    invoice: state.invoices.invoices.find(invoice => invoice._id === props.id),
    products: state.product.products,
    customer: state.customer.customers,
    senderAddress: state.invoice.senderAddress,
    senderCity: state.invoice.senderCity,
    senderTel: state.invoice.senderTel,
    admin: state.auth.auth.user.admin,
    canCancelInvoice: state?.auth?.auth?.user?.canCancelInvoice,
    isLoading: state.invoices.isLoading,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    cancelInvoice: (id, products) => dispatch(cancelInvoice(id, products)),
    setProductsFromDatabase: () => dispatch(setProductsFromDatabase()),
    reasonForCancle: (id, reason) => dispatch(reasonForCancle(id, reason)),
    reverceReasonForCancle: id => dispatch(reverceReasonForCancle(id)),
    setDate: data => dispatch({ type: `SET_DATE`, data }),
    setInvoiceNumber: data => dispatch({ type: `SET_INVOICE_NUMBER`, data }),
    // setInvoiceId: id => dispatch(setInvoiceId(id)),
    setCustomer: customer => dispatch(setCustomer(customer)),
    setProducts: products => dispatch(setProducts(products)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewInvoice)
