import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import {
  FaArrowLeft,
  FaArrowRight,
  FaSearch,
  FaFileInvoice,
} from "react-icons/fa"
import { Table, Row, Button } from "react-bootstrap"
import moment from "moment"
import { navigate } from "gatsby"
import { HiOutlineDocumentAdd } from "react-icons/hi"
import { setInvoices } from "../../state/actions/invoices"
import { clearInvoice } from "../../state/actions/invoice"
import { setUsers } from "../../state/actions/user"
import Loading from "../loading"

import styles from "../../styles/components/invoices/invoices.module.scss"

const Invoices = props => {
  console.log(props)

  const [currentPage, setCurrentPage] = useState(1)
  const [invoicesPerPage, setInvoicesPerPage] = useState(25)
  const [searchTerm, setSearchTerm] = useState("")

  const [value, setValue] = useState("")

  useEffect(() => {
    props.setInvoices({
      skip: currentPage,
      limit: invoicesPerPage,
      search: searchTerm,
    })
    props.setUsers()
  }, [currentPage, invoicesPerPage, searchTerm])

  const invoiceClickHandler = id => {
    navigate(`/app/invoices/${id}`)
  }

  if (props.invoices.length === 0 && !searchTerm) {
    return <Loading />
  }

  const onSearch = searchName => {
    setSearchTerm(searchName)
    setValue(searchName)
    setCurrentPage(1)
  }

  return (
    <div className={styles.wrapper}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h1 className="title">
          <FaFileInvoice />
          Invoices
        </h1>
        <div className="action-buttons">
          {/* <Button
            size="lg"
            variant="success"
            onClick={() => {
              props.clearInvoice()
              localStorage.removeItem("quatationToInvoice")
              navigate("/app/invoices/new")
            }}
          >
            <HiOutlineDocumentAdd
              className="mb-1 mr-2"
              style={{ fontSize: "16px" }}
            />
            New Invoice
          </Button> */}
        </div>
      </div>
      <div className="mt-5">
        {/* Search */}
        <div className={styles.search}>
          <input
            type="text"
            value={value}
            name="search"
            placeholder="Search"
            autoComplete="off"
            className={styles.searchInput}
            onChange={e => {
              setValue(e.target.value)
              setSearchTerm(e.target.value)
              setCurrentPage(1)
            }}
          />
          <FaSearch className={styles.searchIcon} />
        </div>
        <ul className={styles.dropdown}>
          {props.customers
            .filter(customer => {
              const searchName = value.toLowerCase()
              const customerName = customer.name.toLowerCase()

              return (
                searchName &&
                customerName.includes(searchName) &&
                customerName !== searchName
              )
            })
            .map(customer => (
              <div
                className={styles.dropdownRow}
                onClick={() => onSearch(customer?.name)}
              >
                {customer?.name}
              </div>
            ))}
        </ul>

        {props.invoices.length ? (
          <>
            <Table hover responsive>
              <thead>
                <tr>
                  <th>Invoice Number</th>
                  <th>Quotation Number</th>
                  <th>Customer Code</th>
                  <th>Customer Name</th>
                  <th>Date</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {props.invoices.map((invoice, idx) => {
                  return (
                    <tr
                      style={{ cursor: "pointer" }}
                      key={idx}
                      onClick={() => invoiceClickHandler(invoice._id)}
                    >
                      <td>{invoice.invoiceNumber}</td>
                      <td>{invoice.quotationNumber}</td>
                      <td>{invoice.customerCode}</td>
                      <td>
                        {
                          props.customers.find(
                            customer => customer.code === invoice.customerCode
                          )?.name
                        }
                      </td>
                      <td>
                        {moment(new Date(invoice.date)).format("DD-MM-YYYY")}
                      </td>
                      <td>
                        {invoice.cancelled ? (
                          <span className="text-danger">Cancelled</span>
                        ) : invoice.isCanceled ? (
                          <span className="text-danger">
                            Request for cancel
                          </span>
                        ) : (
                          <span className="text-success">Active</span>
                        )}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>

            {/* Pagination */}
            <Row className="d-flex justify-content-center align-items-center my-4 ">
              <div className=" d-flex align-items-center mx-4 my-4 my-lg-0">
                {/* Dropdown to change items per page */}
                <h3
                  className="small d-inline mr-2"
                  style={{
                    minWidth: "105px",
                  }}
                >
                  Items per page:
                </h3>
                <select
                  className="form-control form-control-lg"
                  value={invoicesPerPage}
                  onChange={e => {
                    setCurrentPage(1)
                    setInvoicesPerPage(e.target.value)
                  }}
                >
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>

              <Row className="d-flex justify-content-center align-items-center mx-4">
                <Button
                  size="lg"
                  onClick={() => setCurrentPage(currentPage - 1)}
                  disabled={currentPage === 1}
                  variant={currentPage === 1 ? "secondary" : "primary"}
                >
                  <FaArrowLeft />
                </Button>

                <span className="mx-3 small">
                  Page {currentPage} /{" "}
                  {
                    // Total pages
                    props.totalPages
                  }
                </span>

                <Button
                  size="lg"
                  onClick={() => setCurrentPage(currentPage + 1)}
                  disabled={currentPage >= props.invoiceTotal / invoicesPerPage}
                >
                  <FaArrowRight />
                </Button>
              </Row>
            </Row>
          </>
        ) : (
          <p className="text-muted">No invoices available!</p>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  console.log(state)

  return {
    invoices: state.invoices.invoices,
    quotationNumber: state.invoices.invoices?.map(
      invoice => invoice.quotationNumber
    ),
    invoiceTotal: state.invoices.totalInvoices,
    totalPages: state.invoices.totalPages,
    isLoading: state.invoices.isLoading,
    customers: state.customer.customers,
    users: state.user.users,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setInvoices: data => dispatch(setInvoices(data)),
    setUsers: () => dispatch(setUsers()),
    clearInvoice: () => dispatch(clearInvoice()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Invoices)
