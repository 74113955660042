import React, { useState } from "react"
import { connect } from "react-redux"
import { Button } from "react-bootstrap"
import { FaDownload } from "react-icons/fa"
import axios from "axios"
import Select from "react-select"
import DatePicker from "react-datepicker"
import moment from "moment"

const QuotationReport = ({ customers }) => {
  const [startDate, setStartDate] = useState(moment().startOf("month").toDate())
  const [endDate, setEndDate] = useState(moment().toDate())
  const [customer, setCustomer] = useState("")
  const [data, setData] = useState([])
  const [error, setError] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const downloadReport = async () => {
    setIsLoading(true)
    try {
      const res = await axios.get(
        `${process.env.GATSBY_API_CALL}/reports/quotations`,
        {
          params: { startDate, endDate, customer },
          responseType: "blob",
        }
      )
      window.open(URL.createObjectURL(res.data))
    } catch (error) {
      console.log(error.response)
      setError(error.response)
    } finally {
      setIsLoading(false)
    }
  }

  const generateReport = async () => {
    setIsLoading(true)
    try {
      const res = await axios.get(
        `${process.env.GATSBY_API_CALL}/reports/quotations/data`,
        {
          params: { startDate, endDate, customer },
        }
      )
      setData(res.data)
    } catch (error) {
      setError(error.response.data.message)
    } finally {
      setIsLoading(false)
    }
  }

  const customerOptions = [{ value: "", label: "All Customers" }].concat(
    customers.map(customer => {
      return {
        value: customer.code,
        label: `${customer.code} - ${customer.name}`,
      }
    })
  )

  return (
    <>
      <h2 className="pb-3 font-weight-bold">Quotation Report</h2>
      <div className="row d-flex align-items-end">
        <div className="col-md-3 d-flex flex-column">
          <label>Start date: </label>
          <DatePicker
            selected={startDate}
            onChange={date => setStartDate(date)}
            className="w-100 px-3 py-2"
          />
        </div>
        <div className="col-md-3 d-flex flex-column">
          <label>End date: </label>
          <DatePicker
            selected={endDate}
            onChange={date => setEndDate(date)}
            className="w-100 px-3 py-2"
          />
        </div>

        <div className="col-md-3 d-flex flex-column flex-md-row">
          <Button
            onClick={generateReport}
            size="lg"
            variant="primary"
            className="px-4 py-3 d-flex align-items-center justify-content-center mt-3 mb-2 my-md-0 mr-md-2"
            disabled={isLoading || !startDate || !endDate}
          >
            Generate
          </Button>
          <Button
            onClick={downloadReport}
            size="lg"
            variant="primary"
            className="px-4 py-3 d-flex align-items-center justify-content-center"
            disabled={isLoading || !startDate || !endDate}
          >
            <FaDownload className="mr-2 " />
            Download
          </Button>
        </div>
        {/* Loading spinner and error handling */}
        {/* Loading spinner */}
        {isLoading && (
          <div className="col-md-12 text-center mt-5">
            <div className="spinner-border mt-5" role="status" />
          </div>
        )}

        {data.length > 0 && (
          <div className="col-md-12 mt-5">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Quotation Number</th>
                  <th>Customer</th>
                  <th>Date</th>
                  <th>Created By</th>
                  <th>Credit Confirmed By</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {data.map(quotation => (
                  <tr key={quotation._id}>
                    <td>{quotation.quotationNumber}</td>
                    <td>{quotation.customerName}</td>
                    <td>
                      {moment(new Date(quotation.date)).format("DD-MM-YYYY")}
                    </td>
                    <td>{quotation.createdBy}</td>
                    <td>{quotation.creditConfirmedBy}</td>
                    <td>{quotation.status}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  )
}

const mapStateToProps = state => {
  return {
    customers: state.customer.customers,
  }
}

export default connect(mapStateToProps)(QuotationReport)
