import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Button } from 'react-bootstrap'
import axios from 'axios'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import { FaChartPie, FaDownload } from 'react-icons/fa'
import ItemChart from '../charts/ItemChart'
import ItemPieChart from '../charts/ItemPieChart'

const ItemsReport = ({ customers }) => {
  const [startDate, setStartDate] = useState(moment().startOf('month').toDate())
  const [endDate, setEndDate] = useState(moment().toDate())
  const [customer, setCustomer] = useState('')
  const [data, setData] = useState([])
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const downloadReport = async e => {
    setIsLoading(true) // Set isLoading to true

    try {
      const res = await axios.get(
        `${process.env.GATSBY_API_CALL}/reports/items`,
        {
          params: { startDate, endDate, customer },
          responseType: 'blob'
        }
      )

      window.open(URL.createObjectURL(res.data))
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false) // Set isLoading to false when request completes
    }
  }

  const customerOptions = [{ value: '', label: 'All Customers' }].concat(
    customers.map(customer => {
      return {
        value: customer.code,
        label: `${customer.code} - ${customer.name}`
      }
    })
  )

  const generateReport = async () => {
    setIsLoading(true) // Set isLoading to true
    try {
      const res = await axios.get(
        `${process.env.GATSBY_API_CALL}/reports/items/data`,
        {
          params: { startDate, endDate, customer }
        }
      )
      setData(res.data)
    } catch (error) {
      setError(error.response.data.message)
    } finally {
      setIsLoading(false) // Set isLoading to false when request completes
    }
  }

  return (
    <>
      <h2 className='pb-3 font-weight-bold'>Items Report</h2>

      <div className='row d-flex align-items-end'>
        <div className='col-md-3 d-flex flex-column'>
          <label>Start date: </label>
          <DatePicker
            selected={startDate}
            onChange={setStartDate}
            className='w-100 px-3 py-2'
          />
        </div>
        <div className='col-md-3 d-flex flex-column'>
          <label>End date: </label>
          <DatePicker
            selected={endDate}
            onChange={setEndDate}
            maxDate={moment().toDate()}
            className='w-100 px-3 py-2'
          />
        </div>
        <div className='col-md-3'>
          <label>Customer: </label>&nbsp;
          <Select
            isSearchable={true}
            defaultValue={customerOptions[0]}
            options={customerOptions}
            onChange={e => setCustomer(e.value)}
          />
        </div>
        <div className='col-md-3 d-flex flex-column flex-md-row'>
          <Button
            onClick={generateReport}
            size='lg'
            variant='primary'
            className='px-4 py-3 d-flex align-items-center  justify-content-center mt-3 mb-2 my-md-0 mr-md-2'
            disabled={isLoading || !startDate || !endDate}
          >
            <FaChartPie className='mr-2 ' />
            Generate
          </Button>

          <Button
            onClick={downloadReport}
            size='lg'
            variant='primary'
            className='px-4 py-3 d-flex align-items-center justify-content-center'
            disabled={isLoading || !startDate || !endDate}
          >
            <FaDownload className='mr-2 ' />
            Download
          </Button>
        </div>

        {/* Loading spinner */}
        {isLoading && (
          <div className='col-md-12 text-center mt-5'>
            <div className='spinner-border mt-5' role='status' />
          </div>
        )}

        {/* Invoice chart */}
        {data.length > 0 && !isLoading && (
          <div className='row w-100'>
            <div className='col-12 col-lg-8'>
              <ItemChart
                data={data}
                startDate={moment(startDate).format('MMM D')}
                endDate={moment(endDate).format('MMM D')}
              />
            </div>
            <div className='col-12 col-lg-4'>
              <ItemPieChart
                data={data}
                startDate={moment(startDate).format('MMM D')}
                endDate={moment(endDate).format('MMM D')}
              />
            </div>
          </div>
        )}

        {/* Error */}
        {error && (
          <div className='alert alert-danger mt-5' role='alert'>
            {error}
          </div>
        )}
      </div>
    </>
  )
}

const mapStateToProps = state => {
  return {
    customers: state.customer.customers
  }
}

export default connect(mapStateToProps)(ItemsReport)
