import React, { useRef } from "react"
import moment from "moment"
import { connect } from "react-redux"
import { Button } from "react-bootstrap"
import { confirmAlert } from "react-confirm-alert"
import { navigate } from "gatsby-link"
import DocumentPreview from "../documentPreview"
import BackButton from "../backButton"
import PDFGenerator from "../pdfGenerator"
import {
  setQuoteDate,
  setQuotePaymentMethod,
  setQuoteSalesManager,
  setQuotePaymentTerms,
  setQuoteDeliverTo,
  setQuoteCustomer,
  setQuoteProducts,
  cancelQuotation,
  paymentReceived,
  creditConfirmation,
  setQuoteId,
} from "../../state/actions/quotation"
import { setCustomer, setProducts } from "../../state/actions/invoice"
import { toast } from "react-toastify"
import Loading from "../loading"

const ViewQuotation = props => {
  //set warranty Time for Inverter
  const quotationItems = props.quotation.quotationProducts.map(item => {
    return {
      ...props.products.find(product => product.sku === item.sku),
      price: item.price,
      sku: item.sku,
      discount: item.discount,
      discountType: item.discountType,
      quantity: item.quantity,
      newWarranty: item?.newWarranty,
    }
  })

  const createCopyHandler = () => {
    confirmAlert({
      title: "Copy Quotation",
      message: "Are you sure you want to make a copy from this quotation?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            props.setQuoteDate(moment().format("YYYY-MM-DD"))
            props.setQuotePaymentMethod(props.quotation.paymentMethod)
            props.setQuoteSalesManager(props.quotation.salesManager)
            props.setQuotePaymentTerms(props.quotation.paymentTerms)
            props.setQuoteDeliverTo(props.quotation.deliverTo)
            props.setQuoteCustomer(
              props.customer.find(
                customer => customer.code === props.quotation.customerCode
              )
            )
            props.setQuoteProducts(quotationItems)
            navigate("/app/quotations/new")
          },
        },
        {
          label: "No",
        },
      ],
    })
  }

  const createInvoiceHandler = () => {
    confirmAlert({
      title: "Create Invoice",
      message:
        "Are you sure you want to create an invoice from this quotation?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            let isOutofStock = false

            quotationItems.forEach(item => {
              if (item.quantity > item.stock) {
                isOutofStock = true
                toast.error(`${item.productName} has insufficient stock`)
              }
            })

            if (isOutofStock) return

            props.setDate(moment().format("YYYY-MM-DD"))
            props.setPaymentMethod(props.quotation.paymentMethod)
            props.setSalesManager(props.quotation.salesManager)
            props.setPaymentTerms(props.quotation.paymentTerms)
            props.setTransport(props.quotation.transport)
            props.setDeliverTo(props.quotation.deliverTo)
            props.setQuotationNumber(props.quotation.quotationNumber)
            props.setQuoteId(props.id)

            props.setCustomer(
              props.customer.find(
                customer => customer.code === props.quotation.customerCode
              )._id
            )
            props.setProducts(quotationItems)
            localStorage.setItem("quatationToInvoice", "yes")
            navigate("/app/invoices/new")
          },
        },
        {
          label: "No",
        },
      ],
    })
  }

  const cancelHandler = onClose => {
    props
      .cancelQuotation(props.quotation._id, inputRef.current.value)
      .then(success => {
        navigate("/app/quotations")
      })

    onClose()
  }

  const inputRef = useRef("")

  const confirmCancelQuotation = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui border bg-white p-5 rounded">
            <h1>Cancel Quotation</h1>
            <p>Are you sure you want to cancel this quotation?</p>
            <p>Reason for Cancel</p>
            <input ref={inputRef}></input>

            <br></br>
            <br></br>

            <button className="mr-2 border p-2 " onClick={onClose}>
              No
            </button>
            <button
              className="border p-2"
              onClick={() => cancelHandler(onClose)}
            >
              Yes
            </button>
          </div>
        )
      },
    })
  }

  const confirmPaymentReceived = () => {
    confirmAlert({
      title: "Payment Confirmation",
      message: "Are you sure you want to confirm payment for this quotation?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            props.paymentReceived(props.quotation._id)
          },
        },
        {
          label: "No",
        },
      ],
    })
  }

  const confirmCreditConfirmation = () => {
    confirmAlert({
      title: "Credit Confirmation",
      message: "Are you sure you want to confirm credit for this quotation?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            props.creditConfirmation(props.quotation._id)
          },
        },
        {
          label: "No",
        },
      ],
    })
  }

  const quotationData = {
    documentNumber: props.quotation.quotationNumber,
    senderAddress: props.senderAddress,
    senderCity: props.senderCity,
    senderTel: props.senderTel,
    customer: props.customer.find(
      customer => customer.code === props.quotation.customerCode
    ),
    user: props.quotation.user,
    date: moment(new Date(props.quotation.date)).format("DD-MM-YYYY"),
    paymentMethod: props.quotation.paymentMethod,
    salesManager: props.quotation.salesManager,
    paymentTerms: props.quotation.paymentTerms,
    transport: props.quotation.transport,
    deliverTo: props.quotation.deliverTo,
    quotationProducts: quotationItems,
  }

  return (
    <>
      <div className="mt-4">
        <h1>
          {props.quotation.quotationNumber}{" "}
          {props.quotation.paymentReceived && (
            <span className="text-success">(Payment Received)</span>
          )}
          {props.quotation.creditConfirmation && (
            <span className="text-success">(Credit Confirmed)</span>
          )}
        </h1>

        <BackButton to="/app/quotations" />
        {/* Action Buttons */}
        <div className="action-buttons mt-4">
          <Button
            variant="light"
            className="mr-4"
            onClick={() => createCopyHandler()}
          >
            Create Copy and Edit
          </Button>
          {!props.quotation.cancelled &&
            (props.quotation.paymentReceived ||
              props.quotation.creditConfirmation) && (
              <Button
                variant="light"
                onClick={() => createInvoiceHandler()}
                className="mr-4"
              >
                Create Invoice
              </Button>
            )}
          {!props.quotation.cancelled && (
            <Button
              variant="danger"
              className="mr-4"
              onClick={() => confirmCancelQuotation()}
            >
              Cancel Quotation
            </Button>
          )}

          {!props.quotation.cancelled &&
            !props.quotation.paymentReceived &&
            !props.quotation.creditConfirmation &&
            props.canConfirmCredit &&
            !props.quotation.paymentReceived && (
              <Button
                variant="light"
                onClick={() => confirmCreditConfirmation()}
                className="mr-4"
              >
                Credit Confirm
              </Button>
            )}

          {!props.quotation.cancelled &&
            !props.quotation.paymentReceived &&
            props.canAcceptPayment &&
            !props.quotation.creditConfirmation && (
              <Button variant="light" onClick={() => confirmPaymentReceived()}>
                Payment Confirm
              </Button>
            )}
          {
            <div className="text-danger">
              {props.quotation.cancelled &&
                props.quotation.reasonForCancel &&
                `reason for cancel - ${props?.quotation?.reasonForCancel}`}
            </div>
          }
        </div>
        <DocumentPreview
          data={quotationData}
          warranty="show"
          document="quotation"
        />

        {!props.isLoading && !props.quotation.cancelled && quotationData ? (
          <PDFGenerator data={quotationData} document="quotation" />
        ) : (
          !props.quotation.cancelled && (
            <Loading height="" text="Loading PDF Download" />
          )
        )}
      </div>
    </>
  )
}

const mapStateToProps = (state, props) => {
  return {
    quotation: state.quotation.quotations.find(
      quotation => quotation._id === props.id
    ),

    products: state.product.products,
    customer: state.customer.customers,
    senderAddress: state.invoice.senderAddress,
    senderCity: state.invoice.senderCity,
    senderTel: state.invoice.senderTel,
    admin: state.auth.auth.user.admin,
    canAcceptPayment: state.auth.auth.user.canAcceptPayment,
    canConfirmCredit: state.auth.auth.user.canConfirmCredit,
    isLoading: state.quotation.isLoading,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    // Create Copy Actions
    setQuoteDate: date => dispatch(setQuoteDate(date)),
    setQuotePaymentMethod: method => dispatch(setQuotePaymentMethod(method)),
    setQuoteSalesManager: manager => dispatch(setQuoteSalesManager(manager)),
    setQuotePaymentTerms: terms => dispatch(setQuotePaymentTerms(terms)),
    setQuoteDeliverTo: deliverTo => dispatch(setQuoteDeliverTo(deliverTo)),
    setQuoteCustomer: customer => dispatch(setQuoteCustomer(customer)),
    setQuoteProducts: products => dispatch(setQuoteProducts(products)),
    setQuoteId: id => dispatch(setQuoteId(id)),

    // Create Invoice Actions
    setDate: data => dispatch({ type: `SET_DATE`, data }),
    setPaymentMethod: data => dispatch({ type: `SET_PAYMENT_METHOD`, data }),
    setSalesManager: data => dispatch({ type: `SET_SALES_MANAGER`, data }),
    setPaymentTerms: data => dispatch({ type: `SET_PAYMENT_TERMS`, data }),
    setTransport: data => dispatch({ type: `SET_TRANSPORT`, data }),
    setDeliverTo: data => dispatch({ type: `SET_DELIVER_TO`, data }),
    setQuotationNumber: data =>
      dispatch({ type: `SET_INVOICE_QUOTATION_NUMBER`, data }),
    setCustomer: customer => dispatch(setCustomer(customer)),
    setProducts: products => dispatch(setProducts(products)),

    // Cancel Quotation
    cancelQuotation: (id, reason) => dispatch(cancelQuotation(id, reason)),

    // paymentReceived
    paymentReceived: id => dispatch(paymentReceived(id)),

    // Credit Confirmation
    creditConfirmation: id => dispatch(creditConfirmation(id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewQuotation)
